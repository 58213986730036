import * as React from "react"
import { useEffect, useState } from 'react';
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import sessions from "../../data/sessions.json"
import * as helper from './../helper/utils.js';
import * as statusHelper from './../helper/status.js';
import { Button } from 'react-bootstrap';
import { Badge } from 'react-bootstrap';
import { Table } from 'react-bootstrap';

const SessionList2 = (props) => { 
  const [status, setStatus] = useState();
  const [lastChange, setLastChange] = useState();
  const [hoverId, setHoverId] = useState();
  const [showDetails, setShowDetails] = useState(false);
  
  function toggleDetails(id) {
    if(showDetails) {
      if(id === hoverId) {
        setShowDetails(false);
        setHoverId(undefined);
      } else {
        setHoverId(id);  
        setShowDetails(true);
      }
    } else {
      setShowDetails(true);
      setHoverId(id);
    }    
  }

  async function wrapStatusSave(id, newStatus) {
    let updatedItemIndex = props.displayList.findIndex(x => x.id === id.toString());
    props.displayList[updatedItemIndex].status = newStatus;
    await statusHelper.save(id, newStatus);
    setLastChange(new Date());
  }
 
  useEffect(() => {    
  }, [props.displayList, lastChange]);

  return (
    <React.Fragment>
      <Table responsive ><tbody>{ props.displayList 
          ?
          props.displayList.map((item, key) => {
            let session = sessions.data.find(x => x.id === item.id.toString());
            return (              
              <React.Fragment>
              <tr key={item.id} className={(session?.cancelled ? "text-decoration-line-through" : "") + ' d-none d-lg-block'} >
                <td className="text-nowrap">
                  {(status != 1) ? <span><Button variant="light" size="sm" onClick={() => wrapStatusSave(item.id, 1)} style={{ backgroundColor: statusHelper.checkSelected(item.status, 1) }}>{helper.nameStatus(1)}</Button>&nbsp;</span> : ''}
                  {(status != 2) ? <span><Button variant="light" size="sm" onClick={() => wrapStatusSave(item.id, 2)} style={{ backgroundColor: statusHelper.checkSelected(item.status, 2) }}>{helper.nameStatus(2)}</Button>&nbsp;</span> : ''}
                  {(status != 3) ? <span><Button variant="light" size="sm" onClick={() => wrapStatusSave(item.id, 3)} style={{ backgroundColor: statusHelper.checkSelected(item.status, 3) }}>{helper.nameStatus(3)}</Button>&nbsp;</span> : ''}
                  {(status != 4) ? <span><Button variant="light" size="sm" onClick={() => wrapStatusSave(item.id, 4)} style={{ backgroundColor: statusHelper.checkSelected(item.status, 4) }}>{helper.nameStatus(4)}</Button></span> : ''}
                </td>
                <td className="text-nowrap">{helper.scheduleTime(item?.begin, item?.end)}</td>
                <td onClick={() => toggleDetails(item.id)}>
                  <Badge bg={session?.track?.id}>{helper.trackName(session?.track?.id)?.substring(0,2).toUpperCase()}</Badge>
                  {(session?.subconference?.id === "-txt-long-live-the-word-") ? <React.Fragment>&nbsp;<Badge bg='txt'>.txt</Badge></React.Fragment> : ''}
                  {(session?.subconference?.id === "tincon-x-re-publica-22") ? <React.Fragment>&nbsp;<Badge bg='TINCON'>TINCON</Badge></React.Fragment> : ''}
                </td>
                <td onClick={() => toggleDetails(item.id)}>
                  {(session?.cancelled) ? <span><Badge bg="danger">CANCELED</Badge>&nbsp;</span>:<React.Fragment></React.Fragment>}
                  <Link to={`/session?id=${item.id}`} state={{ id: item.id}}>{session?.title}</Link>
                  {(showDetails && item.id === hoverId) ? <span><br></br>{item?.abstract}</span> : ''}
                </td>                                
              </tr>
              <tr key={'sm1' + item.id} className={(session?.cancelled ? "text-decoration-line-through" : "") + ' d-lg-none'} >
                <td colSpan={3} >
                  <Badge bg={session?.track?.id}>{helper.trackName(session?.track?.id)?.substring(0,2).toUpperCase()}</Badge>
                  {(session?.subconference?.id === "-txt-long-live-the-word-") ? <React.Fragment>&nbsp;<Badge bg='txt'>.txt</Badge></React.Fragment> : ''}
                  {(session?.subconference?.id === "tincon-x-re-publica-22") ? <React.Fragment>&nbsp;<Badge bg='TINCON'>TINCON</Badge></React.Fragment> : ''}
                  &nbsp;
                  {(status != 1) ? <span><Button variant="light" size="sm" onClick={() => wrapStatusSave(item.id, 1)} style={{ backgroundColor: statusHelper.checkSelected(item.status, 1) }}>{helper.nameStatus(1)}</Button>&nbsp;</span> : ''}
                  {(status != 2) ? <span><Button variant="light" size="sm" onClick={() => wrapStatusSave(item.id, 2)} style={{ backgroundColor: statusHelper.checkSelected(item.status, 2) }}>{helper.nameStatus(2)}</Button>&nbsp;</span> : ''}
                  {(status != 3) ? <span><Button variant="light" size="sm" onClick={() => wrapStatusSave(item.id, 3)} style={{ backgroundColor: statusHelper.checkSelected(item.status, 3) }}>{helper.nameStatus(3)}</Button>&nbsp;</span> : ''}
                  {(status != 4) ? <span><Button variant="light" size="sm" onClick={() => wrapStatusSave(item.id, 4)} style={{ backgroundColor: statusHelper.checkSelected(item.status, 4) }}>{helper.nameStatus(4)}</Button></span> : ''}
                  <div onClick={() => toggleDetails(item.id)}>
                    {(session?.cancelled) ? <span><Badge bg="danger">CANCELED</Badge>&nbsp;</span>:<React.Fragment></React.Fragment>}
                    {helper.scheduleTime(item?.begin, item?.end)}<br></br>
                    <Link to={`/session?id=${item.id}`} state={{ id: item.id}}>{session?.title}</Link>
                    {(showDetails && item.id === hoverId) ? <span><br></br>{item?.abstract}</span> : ''}
                  </div>
                </td>
              </tr>
              </React.Fragment>
              
            );
            })
          :
          <tr><td>No matching results.</td></tr>
          }</tbody>
        </Table>        
    </React.Fragment>
)}

export default SessionList2